import { reactive } from "vue";
import { reportUpdate } from '@/request/dishApi';
import { ElMessage, ElLoading } from 'element-plus';

// 重命名弹框
export default (getList) => {
    const state = reactive({
        show: false,
        row: {}
    })

    // 打开
    function open(row) {
        state.row = row;
        state.show = true;
    }

    // 关闭
    function close() {
        state.row = {};
        state.show = false;
    }

    // 提交
    async function submit(form) {
        const loading = ElLoading.service()
        const { code } = await reportUpdate({ ...form, id: state.row.id })
        loading.close()
        if (code === '0') {
            ElMessage({ type: 'success', message: '操作成功' })
            getList()
            close()
        }
    }

    return { state, open, close, submit }
}