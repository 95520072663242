<template>
    <el-dialog :model-value="show" title="重命名报告名称" width="400px" @close="closeHandle">
        <el-form ref="formRef" :model="state.form" :rules="state.rules" label-width="70px" label-position="left">
            <el-form-item prop="name" label="新名称">
                <el-input v-model.trim="state.form.name" placeholder="请输入" />
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitForm(formRef)">确定</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script setup>
import { reactive, ref } from 'vue';

defineProps({
    show: { type: Boolean, required: true }
})

const emits = defineEmits(['submit', 'close'])

const formRef = ref(null)

const state = reactive({
    form: { name: '' },
    rules: { name: [{ required: true, message: '请输入新名称', trigger: 'blur' }] }
})

// 关闭
function closeHandle() {
    emits('close')
}

// 提交
function submitForm(formEl) {
    if (!formEl) return;
    formEl.validate((valid) => {
        if (valid) {
            emits('submit', state.form)
        }
    })
}
</script>