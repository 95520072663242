import { useRouter } from 'vue-router';

export default (tempList) => {
    const $router = useRouter();

    // 跳转新建报告
    function goToNewReport(id) {
        const option = { path: '/dishsearch/reportbasic' }
        if (id) {
            option.query = { id }
        }
        $router.push(option)
    }

    // 跳转报告结果
    function toResult(row) {
        const obj = tempList.value.find(item => item.id === row.outcomeTemplate)
        if (!obj) return;
        $router.push({ path: obj.resultPath, query: { id: row.id } })
    }

    // 继续编辑
    function continueEdit(row) {
        $router.push({ path: '/dishsearch/generatereport', query: { page: 1, id: row.id } })
    }

    return { goToNewReport, toResult, continueEdit }
}