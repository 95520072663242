<template>
    <div class="history_page">
        <section class="list_box">
            <div class="tit_box">
                <small-title tit="历史报告"></small-title>
            </div>
            <div class="search_box">
                <el-select placeholder="报告类型" v-model="state.query.reportType" @change="searchFn" clearable>
                    <el-option label="趋势增长菜" value="UP"></el-option>
                    <el-option label="热卖推荐菜" value="TOP"></el-option>
                </el-select>
                <el-button type="primary" @click="goToNewReport(null)">新建报告</el-button>
            </div>
            <div class="table_box">
                <el-table :data="state.tableData" stripe border style="width: 100%" height="551"
                    :header-cell-style="{ background: '#f5f5f5', color: '#80899b', fontWeight: 400 }">
                    <el-table-column label="序号" width="50" align="center">
                        <template #default="scope">
                            {{ (state.query.pageNum - 1) * state.query.pageSize + scope.$index + 1 }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="createTime" label="创建日期" />
                    <el-table-column prop="name" label="报告名称" />
                    <el-table-column prop="remarks" label="报告描述" show-overflow-tooltip />
                    <el-table-column prop="reportType" label="类型">
                        <template #default="scope">
                            <span>{{ state.typeFormat[scope.row.reportType] }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="outcomeTemplate" label="报告模板">
                        <template #default="scope">
                            <span>{{ tempFormat(scope.row) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="260px" align="center">
                        <template #default="scope">
                            <el-button type="text" size="small" v-if="scope.row.state === 'END'"
                                @click="goToNewReport(scope.row.id)">生成新报告</el-button>
                            <el-button type="text" size="small" @click="renameOpen(scope.row)">重命名</el-button>
                            <el-button type="text" size="small" v-if="scope.row.state === 'END'"
                                @click="toResult(scope.row)">查看报告结果</el-button>
                            <el-button type="text" size="small" v-if="scope.row.state === 'CONDUCT'"
                                @click="continueEdit(scope.row)">继续编辑</el-button>
                            <!-- <el-button type="text" size="small" @click="deleteHandel(scope.row)" style="color:#F56C6C">
                                删除
                            </el-button> -->
                        </template>
                    </el-table-column>
                </el-table>
                <div class="no_data" v-if="state.tableData.length === 0">
                    <el-empty description="暂无数据"></el-empty>
                </div>
            </div>
            <div class="page_box">
                <el-pagination layout="prev, pager, next" v-model:current-page="state.query.pageNum" background
                    :page-size="state.query.pageSize" :total="state.total"></el-pagination>
            </div>
        </section>
    </div>
    <!-- 重命名弹框 -->
    <rename-dialog :show="renameState.show" @close="renameClose" @submit="renameSubmit"></rename-dialog>
</template>

<script setup>
import { onBeforeMount, reactive, watch } from 'vue';
import SmallTitle from '@/components/common/SmallTitle.vue';
import RenameDialog from './RenameDialog.vue';
import useRename from './useRename';
import { reportGetList } from '@/request/dishApi';
import useRouterJump from './useRouterJump';
import useReportTempStore from '@/store/reportTemp.js';
import { storeToRefs } from 'pinia';
import useDelete from './useDelete';

const reportTempStore = useReportTempStore()// 报告模板pinia
const { tempList } = storeToRefs(reportTempStore)

const state = reactive({
    query: {
        reportType: '',
        pageNum: 1,
        pageSize: 10
    },
    tableData: [],
    total: 0,
    typeFormat: {
        UP: '趋势增长菜',
        TOP: '热卖推荐菜'
    }
})

// 获取列表
async function getList() {
    const { data } = await reportGetList(state.query)
    state.tableData = data?.list || [];
    state.total = data?.total || 0;
}

// 分页
watch(() => state.query.pageNum, () => { getList() })

// 搜索
function searchFn() {
    state.query.pageNum = 1;
    getList()
}

// 模板格式化
function tempFormat(row) {
    const obj = tempList.value.find(item => item.id === row.outcomeTemplate)
    return obj?.name || '-'
}

// 重命名
const { state: renameState, open: renameOpen, close: renameClose, submit: renameSubmit } = useRename(getList)

// 删除
const { deleteHandel } = useDelete(getList)

// 跳转
const { goToNewReport, toResult, continueEdit } = useRouterJump(tempList)

onBeforeMount(() => {
    getList()
})
</script>

<style scoped lang="scss">
.history_page {
    padding: 20px 40px;

    .list_box {
        background-color: #fff;
        border-radius: 14px;
        padding: 20px;

        .tit_box {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .search_box {
            display: flex;
            justify-content: space-between;
            padding-top: 20px;
        }

        .table_box {
            padding-top: 20px;
            position: relative;

            .no_data {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                text-align: center;
                padding-top: 180px;
            }
        }

        .page_box {
            text-align: right;
            padding: 20px 0;
        }
    }
}
</style>