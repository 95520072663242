import { ElMessageBox, ElMessage } from 'element-plus';
import { reportDel } from '@/request/dishApi';

export default (getList) => {
    // 删除处理
    function deleteHandel(row) {
        ElMessageBox.confirm('确认删除？', '提示', { confirmButtonText: '确认', cancelButtonText: '取消', type: 'warning' }
        ).then(() => {
            deleteRequest(row)
        })
    }

    // 删除请求
    async function deleteRequest(row) {
        const { code } = await reportDel({ reportId: row.id })
        if (code === '0') {
            ElMessage({ type: 'success', message: '操作成功' })
            getList()
        }
    }

    return { deleteHandel }
}